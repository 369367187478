.program-header {
	width: 100%;
	height: auto;
	min-height: 365px;
	background-image: url("/images/program-bg.png");
	background-size: cover;
	background-repeat: no-repeat, repeat;	
  	background-position: top center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
  }
#sectionProgram {
    position:relative;
	top:-180px;
	width: 100%;
	min-height: calc(100vh - 70px);
	background: url("/images/bg-drawer.png") 0 70px / 100% auto no-repeat;
	padding: 140px 35px 0;
	
	p {white-space: pre-line;}

	.date-selector {
		width: 100%;
		padding-bottom: 30px;
		p {
			margin-top: 25px;
		}
		select {
			padding-left: 0px!important;
			padding-right: 10px;
			background: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.76323 6.25L8.82844e-05 -8.32814e-07L9.52637 0L4.76323 6.25Z' fill='%23040404'/%3E%3C/svg%3E%0A") no-repeat 95% 50%;
			-moz-appearance: none; 
			-webkit-appearance: none; 
			height: auto;
			width: 115px;
		}
		select:focus {
			outline: none;
		}
	}
	.date-header {
		width: 85%;
		display: inline-flex;
  		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.date-header div {
		flex: 1;
  		text-align: left;
	}
	.event-header div {
		flex: 1;
  		text-align: left;
	}
	.event-body p {margin-bottom:0px}
	.event-header {
		width: 100%;
		margin-top: 30px;
	}
	.event-header.bb {
		border-bottom: 1px solid #D8D8D8!important;
		padding-bottom: 25px;
	}
	.events-data {}

	div.bb {
		border-bottom: 1px solid #D8D8D8!important;
		padding-bottom: 30px;
	}

	.date-selector div {
		float: left;
		width: 50%;
	}

	.event-header div {
		float: left;
		width: 100%;
	}
	.date-select, .date-selector {
		border: none;
		font-family: Guardian Egyp;
		font-style: italic;
		font-weight: normal;
		font-size: 55px;
		line-height: 55px;
		color: #000000;
	}
	.infoTable {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 40px 0;
	}
	.feature-image {
		padding: 30px 0 10px 0;
		text-align: center;
	}
	.location-link {
		font-family: BentonSans;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 25px;
		color: #303030;
		padding-left: 30px
	}
	.location-link a {
		color: #303030;
		padding-right: 10px;
	}
	.feature-link {
		font-family: BentonSans;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 17px;
		text-transform: uppercase;
		color: #53565A;
		margin-top: 20px;
		padding-left: 30px	
	}
	.feature-link a {
		color: #53565A;
		padding-right: 10px;
		text-decoration: none!important;
	}
	p.intro {margin: 50px 0 50px}
	p.pt-10 {padding-top: 10px;}
	h1 {margin-bottom: 0px;}
	h2 {
		margin-bottom: 6px;
		padding-left: 30px;
	}
	h2.event {
		white-space: pre-wrap;
		margin-bottom: 6px;
		padding-left: 0px;
		padding-bottom: 20px;
		padding-top: 24px;
	}
	
	h5 {padding-top: 75px;}
	h6 {line-height: 45px;}

	h1.title-two {
		margin-bottom: 0px;
		padding-bottom: 10px;
		padding-left: 15px;
		line-height: 45px;
	}

	.fade-out-fade-in {
		transition: all 1.5s ease;
		-webkit-animation: fadeout 1.5s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadeout 1.5s; /* Firefox < 16 */
		-ms-animation: fadeout 1.5s; /* Internet Explorer */
		-o-animation: fadeout 1.5s; /* Opera < 12.1 */
		animation: fadeout 1.5s;

		-webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 1.5s; /* Firefox < 16 */
		-ms-animation: fadein 1.5s; /* Internet Explorer */
		-o-animation: fadein 1.5s; /* Opera < 12.1 */
		animation: fadein 1.5s;
	}

	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}


	/*  */
	

	@keyframes fadeout {
		from { opacity: 1; }
		to   { opacity: 0; }
	}

	/* Firefox < 16 */
	@-moz-keyframes fadeout {
		from { opacity: 1; }
		to   { opacity: 0; }
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadeout {
		from { opacity: 1; }
		to   { opacity: 0; }
	}

	/* Internet Explorer */
	@-ms-keyframes fadeout {
		from { opacity: 1; }
		to   { opacity: 0; }
	}

	/* Opera < 12.1 */
	@-o-keyframes fadeout {
		from { opacity: 1; }
		to   { opacity: 0; }
	}
}