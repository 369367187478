/* Map section and all its subclasses */

#sectionMap {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	.mapOuter {
		width: 100%;
		height: 100%;
		overflow: scroll;
		position: relative;
		background-color: #ebebeb;
	}
	.mapInner {
		width: 1920px;
		height: calc(1080px + 20vh);
		background: url("/images/map.jpg") top left / 1920px 1080px no-repeat;
	}
	.hotspot {
		// top/left established via React
		position: absolute;
		width: 50px;
		height: 50px;
		margin-top: -25px;
		margin-left: -25px;
		border-radius: 50px;
		box-sizing: content-box;
		cursor: pointer;
		.circle {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50px;
		}
		.grey {
			border: 2px solid $white;
			background-color: $black;
			transition: all 0.2s ease-in-out;
		}
		.white {
			border: 1px solid $white;
			background-color: $white;
			transform: scale(0.16);
			transition: all 0.5s ease-out;
		}
		.svgEye {
			position: absolute;
			top: 14.0px;
			left: 7px;
			transform: scale(0.5);
			transition: transform 0.5s ease-out;
			path, circle {
				stroke: transparent;
				transition: all 0.5s ease-out;
			}
			circle {
				fill: $white;
			}
		}
		.tooltip {
			display: block;
			position: absolute;
			bottom: 54px;
			left: -75px;
			width: 200px;
			text-align: center;
			pointer-events: none;
			transform-origin: center bottom;
			transform: scale(0);
			transition: transform 0.3s ease-out;
			.caret {
				position: absolute;
				bottom: -5px;
				left: calc(50% - 5px);
				width: 0; 
				height: 0; 
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid $black;
			}
			.toolText {
				display: inline-block;
				background: $black;
				color: $white;
				white-space: nowrap;
				padding: 2px 10px;
				border-radius: 8px;
				margin: 0;
			}
		}
		// Active animation
		&.active {
			.grey {
				transform: scale(0.7);
				border: 2px solid transparent;
			}
			.white {
				transform: scale(1.2);
				background: rgba($black, 0.2);
			}
			.tooltip {
				transform: scale(1);
			}
		}
		// First visit animation
		&.visited {
			.svgEye {
				transform: scale(0.7);
				path, circle {
					stroke: $white;
					fill: transparent;
				}
			}
		}
		// Multi-hotspot classes
		&.multiple {
			.quantity {
				color: $white;
				text-align: center;
				position: absolute;
				width: 100%;
				top: 13px;
				font-weight: 700;
				pointer-events: none;
				transform-origin: 50% 50%;
				transform: scale(1);
				transition: transform 0.3s ease-out;
			}
			.close {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				path {
					fill: none;
					stroke: $white;
					stroke-width: 2px;
					transition: transform 0.3s ease-out 0.2s;
					transform: scale(0.001);
					transform-origin: 50% 50%;
				}
			}
			.listBox {
				display: block;
				background: $black;
				color: $white;
				border-radius: 8px;
				text-align: left;
				padding: 0 10px;
				pointer-events: auto;
			}
			.listItem {
				margin-bottom: 0px;
				line-height: 2.5em;
				.title {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: inline-block;
					width: 90%;
					vertical-align: middle;
				}
				.arrow {
					float: right;
				}
				&.selected {
					opacity: 0.5;
					.arrow {
						opacity: 0;
					}
				}
			}
			&.active {
				.quantity {
					transform: scale(0);
				}
				.close path {
					transform: scale(0.3);
				}
			}
		}
	}
	.drawer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		color: $black;
		transform: translateY(calc(100% + 50px));
		transition: transform 0.7s ease-in-out;
		.drawerBgnd {
			height: 100vw;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			pointer-events: none;
			background: url("/images/bg-drawer.png") top left / 100% auto no-repeat;
			width: 100vw;
			transform: translateY(50px);
			transition: transform 0.7s ease-in-out 0.3s;
		}
		.drawerCloseSVG {
			position: absolute;
			top: -40px;
			left: 5px;
			width: 50px;
			height: 50px;
			cursor: pointer;
			path {
				fill: none;
				stroke: $grey;
				stroke-width: 2px;
			}
		}
		&.open {
			transform: translateY(0%);
			.drawerBgnd {
				transform: translateY(-40px);
			}
		}
		.drawerGrid {
			padding-left: 20px;
			width: 60vw;
			height: 40vw;

			display: inline-flex;
			flex-wrap: wrap;
			align-content: center;
			h2 {
				flex-basis: 100%;
				margin-bottom: 10px;
			}
			h5 {
				flex-basis: 100%;
				text-align: left;
				letter-spacing: 0;
				margin-bottom: 10px;
			}
			.dataPoint {
				flex-basis: 50%;
			}
		}
		.drawerImage {
			width: 40vw;
			height: 40vw;
			float: right;
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
	.drawerNav {
		height: 50px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		position: relative;
		z-index: 1;
		.arrow {
			width: 50px;
			height: 50px;
			path {
				fill: none;
				stroke: $grey;
				stroke-width: 2px;
			}
		}
		.moreBtn {
			border: 1px solid $black;
			text-transform: uppercase;
			padding-top: 2px;
			margin: 0 40px;
			flex: 1;
			text-align: center;
			cursor: pointer;
		}
	}

	.introOverlay {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		text-align: center;
		color: $white;
		padding: 0 40px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		&.hidden {
			display: none;
		}
	}
}
