/* Font syles taken from Figma's stleguide section */
// Extra-large, italic, light
h1 {
	font-family: Guardian Egyp;
	font-style: italic;
	font-weight: 300;
	font-size: 53px;
	line-height: 55px;
	margin-bottom: 40px;

	@media (max-width: 400px) {
		font-size: 48px;
		line-height: 48px;
	}
	@media (max-width: 360px) {
		font-size: 45px!important;
		line-height: 45px;
	}
}

// Med, regular, light
h2 {
	font-family: Guardian Egyp;
	font-style: normal;
	font-weight: 400;
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 26px;
	&.thin {
		font-weight: 300;
	}
	&.italic {
		font-style: italic;
	}
	@media (max-width: 400px) {
		font-size: 22px;
		line-height: 27px;
	}
	@media (max-width: 360px) {
		font-size: 20px;
		line-height: 25px;
	}
}

// Large, italic, light
h3 {
	font-family: Guardian Egyp;
	font-style: italic;
	font-weight: 300;
	font-size: 35px;
	line-height: 40px;
	margin-bottom: 26px;
}

// Label for info
h4 {
	font-family: Guardian Egyp;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	&.footer,
	&.italic {
		font-weight: 400;
		font-style: italic;
		line-height: 25px;
	}
}

// Serif title line 1
h5 {
	font-family: Guardian Egyp;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	text-align: center;
	letter-spacing: 0.1em;
	color: #303030;
}

// Serif title line 2
h6 {
	font-family: Guardian Egyp;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	line-height: 55px;
	letter-spacing: 0.08em;
	color: #303030;
	text-align: center;
}

// Paragraph copy
p {
	font-family: BentonSans;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 25px;
	margin-bottom: 10px;

	// Tiny for info
	&.small {
		font-size: 12px;
		line-height: 20px;
		white-space: pre-line;
	}
	&.small a {
		text-decoration: none;
		color: $grey;
	}
	&.tiny {
		font-size: 10px;
		margin-bottom: 0;
	}
}

// Horizontal Rule
hr {
	width: 31px;
	height: 1px;
	margin: 0 auto;
	border: none;
	background: $black;
	margin: 25px auto;
}

// <Link to=""> as button to avoid page refresh
.linkButton {
	display: block;
	width: 100%;
	padding: 13px 0;
	text-align: center;
	font-family: BentonSans;
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 1em;
	text-transform: uppercase;
	text-decoration: none;
	color: $grey;
	border: 1px solid $grey;
	cursor: pointer;

	&.white {
		color: $white;
		border-color: $white;
	}
	a {
		color: $grey;
		text-decoration: none;
	}
}

.text-prewrap {
	white-space: pre-wrap;
}
.text-center {
	text-align: center;
}

///// Scanner button ?
button {
	-webkit-appearance: none;
	margin-top:30px!important;
	font-family: BentonSans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 21px;
	text-align: center;
	text-transform: uppercase;
	color: #53565A;
	position: relative;
	width: 100%;
	max-width: 400px;
	height: 55px;
	background: transparent;
	border: 1px solid #53565A!important;
	color: #53565A;
	margin: 0 auto;
	border: none;
	border-radius: 0px;
}

button a {
	transform: translate(0%, -50%);
	color: #53565A;
	text-decoration: none;
	text-align: center;
	margin: 0 auto;
	width: 100%;
}
