////////////////// COLORS //////////////////
// Primary
$blue: #2f6dc8;
$grey: #424242;

// Secondary
$white: #ffffff;
$black: #181818;
$beige: #f4eee8;
$greyLite: #53565A;

$navHeight: 60px;

$easeInOut: cubic-bezier(0.75, 0, 0.25, 1);
$easeOut: cubic-bezier(0.0, 0.75, 0.25, 1);
$easeIn: cubic-bezier(0.75, 0.0, 1, 0.25);
