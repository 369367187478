@font-face {
    font-family: 'BentonSans';
    src: url('./fonts/BentonSans-Bold.eot');
    src: url('./fonts/BentonSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/BentonSans-Bold.woff2') format('woff2'),
        url('./fonts/BentonSans-Bold.woff') format('woff'),
        url('./fonts/BentonSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BentonSans';
    src: url('./fonts/BentonSans-Regular.eot');
    src: url('./fonts/BentonSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/BentonSans-Regular.woff2') format('woff2'),
        url('./fonts/BentonSans-Regular.woff') format('woff'),
        url('./fonts/BentonSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BentonSans';
    src: url('./fonts/BentonSans-Black.eot');
    src: url('./fonts/BentonSans-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/BentonSans-Black.woff2') format('woff2'),
        url('./fonts/BentonSans-Black.woff') format('woff'),
        url('./fonts/BentonSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

