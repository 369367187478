#navbar {
	position:fixed;
	top: 0;
	width:100%;
	height: $navHeight;
	background: #ffffff;
	padding: 0 20px;
	z-index: 10;
	transition: background 0.5s ease-out;

	display: flex; 
	flex-direction: row; 
	align-items: center; 
	justify-content: space-between;
	svg {
		display: block;
		cursor: pointer;
	}
	.navToggle {
		margin-right: 16px;
		path{
			stroke: $black;
			fill: none;
			transition: stroke 0.5s ease-out 0.0s;
		}
	}
	.navLogo path {
		fill: $black;
		transition: fill 0.5s ease-out 0.1s;
	}
	.navScanner {
		.eye,
		.crosshairs {
			fill: none;
			stroke: $black;
			transition: stroke 0.5s ease-out 0.2s;
		}
		.eye {
			stroke-width: 2;
		}
	}

	// Transparent bg with white SVGs
	&.invert {
		background: transparent;
		.navToggle path {
			stroke: $white;
		}
		.navLogo path {
			fill: $white;
		}
		.navScanner {
			.eye, 
			.crosshairs {
				stroke: $white;
			}
		}
	}
}

#menu {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	pointer-events: none;
	#menuBack {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		path {
			opacity: 0.9;
		}
	}

	#menuLinks { 
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		padding: $navHeight 0;
		color: $white;

		display: flex;
		flex-direction: column;
		align-items: center; 
		justify-content: center;
		.navLink {
			margin: 8px 0;
			text-decoration: none;
			color: inherit;
			display: block;
			overflow: hidden;
			h3 {
				margin-bottom: 0;
				line-height: 1em;
				transform: translateY(100%);
				padding: 5px;
			}
			&.active {
				// color: $blue;
			}
		}
	}
	.copy {
		color: $white;
		opacity: 0.0;
		visibility: hidden;
		position: absolute;
		bottom: 10px;
		width: 100%;
		text-align: center;
		transition: opacity 1s, visibility 1s;
	}
	&.open {
		pointer-events: auto;
		.copy {
			opacity: 1;
			visibility: visible;
		}
	}
}
