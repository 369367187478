#sectionHome {
	height: 100%;
	a.linkButton {margin-top: 30px;}

	.homeLanding {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100vh;
		padding-bottom: $navHeight;
		color: $white;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.homeVideo {
			position: absolute;
			top: 0;
			background: url("/images/home-bg.png") top center / cover no-repeat;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.homeReticle {
			position: relative;
			margin: -50px 0 0 0;
			width: 275px;
			height: 275px;
			text-align: center;
			cursor: pointer;

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			.corners {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				.corner {
					fill: none;
					stroke: $white;
					stroke-width: 1px;
				}
			}
			.scanTxt {
				margin: 0;
				transform: translateY(-22px);
				opacity: 0;
			}
			.svgEye {
				margin-top: 30px;
				opacity: 0;
			}
		}
		.exploreLink {
			position: absolute;
			bottom: 20vh;
			text-align: center;
			cursor: pointer;
		}
	}

	.homeCollection {
		width: 100%;
		margin-top: 100vh;
		position: relative;

		.bgWave {
			position: absolute;
			top: 0;
			width: 100%;
			padding-top: 110%;
			background: url("/images/bg-drawer.png") top center / 100% auto no-repeat;
		}
		
		.bgWhite {
			position: absolute;
			top: 30vw;
			bottom: 0;
			width: 100%;
			background: $white;
		}
		.homeInner {
			background-position: center;
			background-repeat: repeat-y;
			position: relative;
			padding: 30vw 35px 35px;
		}
		h6 {line-height: 45px;}
		// .artPreview and all subclasses in _common.scss
	}
}
