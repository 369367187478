body {
	background-position: center;
	background-repeat: repeat;
}
main {
	width: 100%;
	height: 100%;
}

.hidden {display: none;}
.visible {display: block;}

// Noise texture that covers entire site
#textureOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	background: url("/images/noise_texture.png");
}
////// REUSABLE SVG ELEMENTS //////
.svgEye {
	path, circle {
		fill: none;
		stroke: $white;
		stroke-width: 2px;
	}
}

////// PRELOADER //////
#preloader {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background: $white;
	overflow: hidden;
	padding: 0 20px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.lowerBox {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;
	}
	.loadInfo {
		opacity: 1;
		transition: 1s opacity ease-out;
	}
	.svgOuter {
		width: 113px;
		height: 45px;
		margin: 0 auto;
		mask-image: linear-gradient(110deg, #fff 0%, #ffffff40 0%);
	}
	.svgEye {
		width: 100%;
		height: 100%;
		path, circle {
			stroke: $grey;
			stroke-width: 1.5px;
		}
	}
	.linkButton {
		position: absolute;
		top: 0%;
		opacity: 0;
		pointer-events: none;
		transition: 1s opacity ease-out 1s;
	}
	.introText {
		text-align: center;
		font-size: 23px;
		line-height: 25px;
		.line {
			transform: translateY(1em);
			mask-image: linear-gradient(#fff 0%, transparent 0%);
			padding-bottom: 5px;
			opacity: 0;
		}
	}
	.fullCover {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: $white;
		pointer-events: none;
		opacity: 0;
	}
	&.complete {
		.loadInfo {
			opacity: 0;
		}
		.linkButton {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

////// Art Preview Collection //////
.artPreview {
	pointer-events: none;
	.imgCrop {
		margin: 20px;
		text-align: center;
		overflow: hidden;
		box-shadow: 0 20px 20px rgba(#000, 0.25);
		position: relative;
		opacity: 0.0;
		transition: opacity 1s ease-out;
		&.rounded {
			border-radius: 3.1vw;
		}
		&.noShadow {
			box-shadow: none;
		}
	}
	.imgInner {
		width:100%;
		display: block;
		transform: scale(2.0);
		transition: transform 1s ease-out;
	}
	.artTitle {
		pointer-events: auto;
		cursor: pointer;
		font-size: 40px;
		line-height: 48px;
	}
	.padded {
		// margin-top: 15px;
		overflow: hidden;
	}
	.feature-link {
		font-family: BentonSans;
		font-style: normal;
		font-weight: bold;
		color: $greyLite;
		opacity: 0;
		transition: opacity 1s ease-out 0.5s;
		pointer-events: auto;
		cursor: pointer;
	}
	.txtArtist {
		line-height: 1.5em;
		margin-bottom: 5px;
		transform: translateY(-100%);
		transition: transform 1s ease-out 0.5s;
		pointer-events: auto;
		cursor: pointer;
	}
	hr {
		width: 100%;
		opacity: 0.5;
	}
	.arrow-right {padding-left: 5px;}

	// Intro animations
	&.visible {
		.imgCrop {
			opacity: 1;
		}
		.imgInner {
			transform: scale(1);
		}
		.txtArtist {
			transform: translateY(0);
		}
		.feature-link {
			opacity: 1;
		}
	}
}

////// Awkward staggererd justificated artwork title //////
.artTitle {
	line-height: 1.2em;
	margin-bottom: 0px;
	.line {
		transform: translateY(-45%);
		mask-image: linear-gradient(#fff -1%, transparent 0%);
		opacity: 0;
	}
	.line1 {
		margin-left: -15px;
	}
	.line2{
		margin-left: 0px;
	}
	.line3{
		margin-left: 15px;
	}
}

////// "Explore the Space" map preview //////
.exploreSpace {
	position: relative;
	padding: 0px 35px 100px;
	max-width: 500px;
	margin: 0 auto;
	.mapImageOuter {
		width: 300px;
		height: 300px;
		margin: 0 auto 25px;
		overflow: hidden;
		position: relative;
		.mapImageInner {
			width: 100%;
			height: 100%;
			background: url("/images/mapThumbnail.jpg") center bottom / cover no-repeat;
			transform: translateY(100%);
		}
	}
	.fakeHotspot {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50px;
		height: 50px;
		margin-top: -25px;
		margin-left: -25px;
		border-radius: 50px;
		box-sizing: content-box;
		background: $grey;
		border: 2px solid $white;
	}
	.exploreMapDesc {
		h1 {
			text-align: center;
			opacity: 0;
			transform: translateY(100%);
		}
	}
}
////// TEMP PLACEHOLDERS //////
#sectionAbout,
#sectionProgramming,
#sectionTerms,
#sectionLegal {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}