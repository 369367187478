/* Scanner section and all its subclasses */


#sectionScan {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	.thinking-qr-code {
		// position: relative;
		width: 100%;
		height: 100%;
		// margin: 0 auto;
		// text-align: center;
	}

	.thinking-qr-code section {
		// position: absolute;
		// top: 50%!important;
		// transform: translate(0%, -50%);
		height: 100%;
		padding-top: 0 !important;
		display: flex; 
		flex-direction: row; 
		align-items: center; 
		align-content: center;
	}

	.thinking-qr-code section div:first-child {
		background-image: url("/images/reticle.png");
		background-repeat: no-repeat, repeat;
		background-position: -50px -50px;
		border: 50px solid rgba(0, 0, 0, 0)!important;
		box-shadow: none!important;
		// transform: translate(0%, -50%);
		position: relative!important;
		width:300px!important;
		height: 300px!important;
		margin: 0 auto;
	}

	.section {
		margin:0 auto; 
		position: absolute;
	}
	a.moreBtn  {text-decoration: none;color: black;}
	

	#noresult {
		position: relative; 
		bottom: 90px; 
		color: #fff; 
		width: 80%;
		text-align: center;
		background-color:rgba(0, 0, 0, 0.4);
		border-radius: 5px;
		margin: 0 auto;
		padding: 10px;
		max-width: 300px;
		font-family: Guardian Egyp;
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		font-style: italic;
		&.footer,
		&.italic {
			font-weight: 400;
			font-style: italic;
			line-height: 25px;
		}
	}

	#error {
		position: relative; 
		bottom: 90px; 
		color: #fff; 
		width: 80%;
		text-align: center;
		background-color:rgba(0, 0, 0, 0.4);
		border-radius: 5px;
		margin: 0 auto;
		padding: 10px;
		max-width: 300px;
		font-family: Guardian Egyp;
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		font-style: italic;
		&.footer,
		&.italic {
			font-weight: 400;
			font-style: italic;
			line-height: 25px;
		}
	}

	#buttonContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	#result {
		font-family: 'Guardian Egyp'; 
		font-weight: 600; 
		font-style: normal;
		font-size: 15px;
		line-height: 18px;
		position: relative;
		width: 296px;
		height: 55px;
		bottom: 100px; 
		background-color: #ffffff;
		border: 1px solid #53565A;
		margin: 0 auto!important;
		border: none;
		border-radius: 0px;
		text-align: center!important
	}

	#result a {
		top:50%;
		transform: translate(0%, -50%);
		color: #53565A;
		text-decoration: none;
		text-align: center;
		margin: 0 auto;
		width: 100%;
	}

	.hidden {display: none;}
	
	.drawer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		color: $black;
		transform: translateY(calc(100% + 50px));
		transition: transform 0.7s ease-in-out;
		.drawerBgnd {
			height: 100vw;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			pointer-events: none;
			background: url("/images/bg-drawer.png") top left / 100% auto no-repeat;
			width: 100vw;
			transform: translateY(50px);
			transition: transform 0.7s ease-in-out 0.3s;
		}
		.drawerCloseSVG {
			position: absolute;
			top: -50px;
			left: 5px;
			width: 50px;
			height: 50px;
			cursor: pointer;
			path {
				fill: none;
				stroke: $grey;
				stroke-width: 2px;
			}
		}
		&.open {
			transform: translateY(0%);
			.drawerBgnd {
				transform: translateY(-50px);
			}
		}
		.drawerGrid {
			padding-left: 20px;
			width: 60vw;
			height: 50vw;

			display: inline-flex;
			flex-wrap: wrap;
			align-content: center;
			h2 {
				flex-basis: 100%;
				margin-bottom: 10px;
			}
			h5 {
				flex-basis: 100%;
				text-align: left;
				letter-spacing: 0;
				margin-bottom: 10px;
			}
			.dataPoint {
				flex-basis: 49%;
			}
		}
		.drawerImage {
			width: 40vw;
			height: 50vw;
			float: right;
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
		}
		.drawerImageEmpty {
			width: 40vw;
			height: 50vw;
			float: right;
			background: none;
		}
	}
	.drawerNav {
		height: 50px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		position: relative;
		z-index: 1;
		.arrow {
			width: 50px;
			height: 50px;
			path {
				fill: none;
				stroke: $grey;
				stroke-width: 2px;
			}
		}
		.moreBtn {
			border: 1px solid $black;
			text-transform: uppercase;
			padding-top: 2px;
			margin: 0 40px;
			flex: 1;
			text-align: center;
			cursor: pointer;
		}
	}
}