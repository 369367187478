@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-Bold.eot');
    src: url('./fonts/GuardianEgyp-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Bold.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Bold.woff') format('woff'),
        url('./fonts/GuardianEgyp-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-BlackIt.eot');
    src: url('./fonts/GuardianEgyp-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-BlackIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-BlackIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-Black.eot');
    src: url('./fonts/GuardianEgyp-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Black.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Black.woff') format('woff'),
        url('./fonts/GuardianEgyp-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-MediumIt.eot');
    src: url('./fonts/GuardianEgyp-MediumIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-MediumIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-MediumIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-SemiboldIt.eot');
    src: url('./fonts/GuardianEgyp-SemiboldIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-SemiboldIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-SemiboldIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-SemiboldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-RegularIt.eot');
    src: url('./fonts/GuardianEgyp-RegularIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-RegularIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-RegularIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-RegularIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-Medium.eot');
    src: url('./fonts/GuardianEgyp-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Medium.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Medium.woff') format('woff'),
        url('./fonts/GuardianEgyp-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-Light.eot');
    src: url('./fonts/GuardianEgyp-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Light.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Light.woff') format('woff'),
        url('./fonts/GuardianEgyp-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp Hairline';
    src: url('./fonts/GuardianEgyp-Hairline.eot');
    src: url('./fonts/GuardianEgyp-Hairline.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Hairline.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Hairline.woff') format('woff'),
        url('./fonts/GuardianEgyp-Hairline.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-LightIt.eot');
    src: url('./fonts/GuardianEgyp-LightIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-LightIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-LightIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-Regular.eot');
    src: url('./fonts/GuardianEgyp-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Regular.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Regular.woff') format('woff'),
        url('./fonts/GuardianEgyp-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp Hairline';
    src: url('./fonts/GuardianEgyp-HairlineIt.eot');
    src: url('./fonts/GuardianEgyp-HairlineIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-HairlineIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-HairlineIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-HairlineIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-BoldIt.eot');
    src: url('./fonts/GuardianEgyp-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-BoldIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-BoldIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-Semibold.eot');
    src: url('./fonts/GuardianEgyp-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Semibold.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Semibold.woff') format('woff'),
        url('./fonts/GuardianEgyp-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-Thin.eot');
    src: url('./fonts/GuardianEgyp-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-Thin.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-Thin.woff') format('woff'),
        url('./fonts/GuardianEgyp-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-Regular.eot');
    src: url('./fonts/GuardianTextEgyp-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-Regular.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-Regular.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-BoldIt.eot');
    src: url('./fonts/GuardianTextEgyp-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-BoldIt.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-BoldIt.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-BlackIt.eot');
    src: url('./fonts/GuardianTextEgyp-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-BlackIt.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-BlackIt.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-MediumIt.eot');
    src: url('./fonts/GuardianTextEgyp-MediumIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-MediumIt.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-MediumIt.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-RegularIt.eot');
    src: url('./fonts/GuardianTextEgyp-RegularIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-RegularIt.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-RegularIt.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-RegularIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-Black.eot');
    src: url('./fonts/GuardianTextEgyp-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-Black.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-Black.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian Egyp';
    src: url('./fonts/GuardianEgyp-ThinIt.eot');
    src: url('./fonts/GuardianEgyp-ThinIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianEgyp-ThinIt.woff2') format('woff2'),
        url('./fonts/GuardianEgyp-ThinIt.woff') format('woff'),
        url('./fonts/GuardianEgyp-ThinIt.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-Bold.eot');
    src: url('./fonts/GuardianTextEgyp-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-Bold.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-Bold.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Guardian TextEgyp';
    src: url('./fonts/GuardianTextEgyp-Medium.eot');
    src: url('./fonts/GuardianTextEgyp-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GuardianTextEgyp-Medium.woff2') format('woff2'),
        url('./fonts/GuardianTextEgyp-Medium.woff') format('woff'),
        url('./fonts/GuardianTextEgyp-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

