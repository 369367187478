/** @format */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
	display: block;
}
audio,
canvas,
video {
	display: inline-block;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
[hidden] {
	display: none;
}
html {
	font-family: sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
html, body {
	width: 100%;
	height: 100%;
}
a:focus {
	outline: none;
}
a:active,
a:hover {
	outline: 0;
}
h1 {
	font-size: 2em;
}
abbr[title] {
	border-bottom: 1px dotted;
}
b,
strong {
	font-weight: 700;
}
dfn {
	font-style: italic;
}
mark {
	background: #ff0;
	color: #000;
}
code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	font-size: 1em;
}
pre {
	white-space: pre-wrap;
	word-wrap: break-word;
}
q {
	quotes: 2 1c 2 1d 2 18 2 19;
}
small {
	font-size: 80%;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
img {
	border: 0;
}
svg:not(:root) {
	overflow: hidden;
}
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}
button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	margin: 0;
}
button,
input {
	line-height: normal;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: button;
	cursor: pointer;
}
button[disabled],
input[disabled] {
	cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}
input[type='search'] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}
textarea {
	overflow: auto;
	vertical-align: top;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body,
figure {
	/* margin: 0; */
}
legend,

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: ' ';
	clear: both;
	height: 0;
}
* {
	box-sizing: border-box;
	margin: 0;
}
html {
	font-size: 62.5%;
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Open Sans', sans-serif;
	font-size: 2rem;
}

/* detect desktop */
@media (min-width:641px) {
	#desktopLock {
		display: flex; 
		flex-direction: column; 
		align-items: center; 
		align-content: center;
	}
	#navbar {display: none!important;}
	#main {display: none!important;}
	#portraitLock {display: none;}
	body {height: 100%;
		overflow: hidden;}	
}

@media (max-width:640px) {
	#desktopLock {
		display: none; 
		
	} 
}

#desktopLock {height: 80%;}

#desktoplockContent {
	position: absolute;
	display: flex;
	flex-direction: column;
	height: 100%;
	height: 100vh;
	width: 100vw;
	align-items: center;
	justify-content: center;
	z-index: 500;
}

#desktoplockContent p {
	font-family: Guardian Egyp;
	font-style: italic;
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	color: #000000;
	padding-top: 30px;
}

.desktoplock-image {
	flex-shrink: 1;

}

.desktoplock-image img {
	max-height: 500px;
	width:100%;
	flex-shrink: 1;
}

@media (orientation: landscape) and (max-height:640px) {
	body {
	  flex-direction: row;
	}
  }

  body.locked {
	height: 100%;
	overflow: hidden;
  }
  
  
  @media (orientation: landscape) and (max-height:640px) {
	#desktopLock {display: none;}
	#portraitLock {
	position: absolute; 
	top:0px; 
	left:0px;  
	width: 100vw; 
	height: 100vh; 
	z-index: 1000; 
	background-color: #fff;
	
	}
	
	
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	#main {display: none;}

	#portraitLock {
		display: flex; 
		flex-direction: column; 
		align-items: center; 
		align-content: center;
	} 
	
	#lockContent {
		display: flex;
		flex-direction: column;
		height: 100vh;
		align-items: center;
		justify-content: center;
	}
	
	#lockContent p {
		display: flex;
		font-family: Guardian Egyp;
		font-style: italic;
		font-weight: normal;
		font-size: 25px;
		line-height: 30px;
		text-align: center;
		color: #000000;
		padding-top: 30px;
	}

	#navContainer {
	  display: none!important;
	}
  }

  @media (orientation: portrait) {
	#navbar {display: flex;}
	#portraitLock {display: none;}
	#main {display: block;}
	#main:first-child {display: block;z-index: 100;}
	.sidenav {display: flex!important;}
  }

  @keyframes hover {
	0% {
	  transform: scale(.5);
	  color: #121212;
	  -webkit-text-stroke: 2px gray;
	}

	20% {
	  transform: scale(1);
	  color: pink;
	  -webkit-text-stroke: 3px red;
	  filter: drop-shadow(0 0 1px black)drop-shadow(0 0 1px black)drop-shadow(0 0 3px red)drop-shadow(0 0 5px red)hue-rotate(10turn);
	}

	50% {
	  transform: scale(.5);
	  color: #121212;
	  -webkit-text-stroke: 2px gray;
	}
  }
