
.artItemPageBg {
	height: 100%;

	.purchaseOverlay {
		z-index: 100;
		position: fixed;
		top: 0vh;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.9);
		text-align: center;
		color: $white;
		padding: 0 40px;
		opacity: 0;
		visibility: hidden;

		display: flex;
		flex-direction: column;
		justify-content: center;

		transition: 1s visibility, 1s opacity;
		&.show {
			visibility: visible;
			opacity: 1;
		}
		.closeSVG {
			width: 70px;
			height: 70px;
			margin: 0 auto;
			cursor: pointer;
			margin-bottom: 50px;
			path, circle {
				fill: none;
				stroke: $white;
			}
			circle {
				stroke-width: 2;
			}
		}
		p {
			margin-bottom: 50px;
		}
		.linkButton {
			margin-bottom: 10px;
		}
		.continueLink {
			color: #fff;
			text-decoration: none;
		}
	}
}


#sectionContainer{
	width: 100%;
	min-height: calc(100vh - 70px);
	text-align: center;
	padding: 20px 5px 0;
}

.artitem.feature-image {
	padding: 50px 0 0px 0;
	text-align: center;
	background: url("/images/bg-art-noise.png");

	@media (min-width: 400px) {
		padding: 50px 0 10px 0;
	}
}

.artitem.feature-image img {
	width:100%

}

.sectionArtwork {
	position: relative;
	z-index: 5;
	margin-top: -80px;
	width: 100%;
	min-height: calc(100vh - 70px);
	background: url("/images/bg-drawer.png") 0 -0px / 100% auto no-repeat;
	padding: 90px 35px 0;
	
	.feature-image {
		padding: 30px 0 10px 0;
		text-align: center;
		z-index: 1;
	}

	button {margin: 0px 0 60px 0!important}
	.infoTable {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 40px 0;

		
	}
	.infoTable div {
		flex-basis: 50%;
	}

	.blockq {
		margin:0 auto;
		text-align: center;
		padding-top: 60px;
		padding-bottom: 60px;

	}
	h2 {margin-top: 16px;}
	h5 {padding-top: 75px;}
	h6 {line-height: 45px;}

	@media (min-width: 400px) {
		margin-top: -100px;
    }

    &.overlaps {
    	position: initial;
    }
}

.sectionArtist {
	position: relative;
	width: 100%;
	min-height: calc(100vh - 70px);
	padding: 70px 35px 50px;

	.feature-image {
		width: calc(100% + 70px);
		position: relative;
		left:-35px;
		margin: 40px 0 40px 0;
	}

	.feature-image.right img {
		float: right;
		max-width: 80%;
		height: auto;
	}

	.feature-image.left img {
		float: left;
		max-width: 80%;
		height: auto;
	}

	.feature-image.none  {
		left:0px;
		width: 100%;
		position: relative;
	}

	.feature-image.none img {
		width: 100%;
		height: auto;
	}

	p {white-space: pre-line;}
	.pre {white-space: pre-line!important;}

	.clear {
		clear: both;
		padding-bottom: 50px;
	}

	h1 {margin-bottom: 0px;margin-left:-15px;}
	h2 {
		margin-top: 16px;
		font-family: Guardian Egyp;
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 25px;
		color: #303030;
		}
	h2 span {
		font-weight: 400;
		padding-left: 5px;
	}
	h2 span a {
		text-decoration: none;
		color: #303030;
	}
	h5 {padding-top: 75px;}
	h6 {line-height: 45px;}
	h1.title-two {
		margin-bottom: 0px;
		padding-bottom: 30px;
		padding-left: 15px;
		line-height: 45px;
	}
}