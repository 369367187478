#footer {
	background: $white url("/images/bg-footer.png") top left / 100% auto no-repeat;
	width: 100%;
	padding: 40vw 90px 25vw;
	text-align: center;
	position: relative;
	overflow: hidden;
	h4.footer {
		margin-bottom: 25px;
	}
	.sponsors {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-items: center;
		.sponsor1 {
			max-width: 70px;
			margin-bottom: 20px;
		}
		.sponsor2 {
			max-width: 130px;
		}
	}
	p.small {
		color: $grey;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
	}
	.waveCover {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform: translateY(0);
		svg {
			width: 100%;
		}
		path {
			fill: $white;
		}
	}
}